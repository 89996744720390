/* eslint-disable */
import { LocaleTypes } from "@/constants/enums";

window._config = window._config || {};
const runtimeConfig = {
  PROJECT_ENV: window._config.PROJECT_ENV,
  BASE_APP_URL: window._config.BASE_APP_URL,
};
/* eslint-enable */

// env variables
export const PROJECT_ENV = runtimeConfig.PROJECT_ENV || process.env.VUE_APP_PROJECT_ENV;
export const BASE_APP_URL = runtimeConfig.BASE_APP_URL || process.env.VUE_APP_BASE_APP_URL;

// general variables
export const SUPPORTED_LOCALES = [LocaleTypes.Ar, LocaleTypes.En];
export const DEFAULT_LOCALE = LocaleTypes.Ar;
export const IS_PROD = PROJECT_ENV === 'prod';
