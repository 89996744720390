import Vue from 'vue';
import { setupVueGlobal } from '@/setup/vue';
import { setupGlobalComponents, setupGlobalIcons } from './setup/components';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

setupVueGlobal();
setupGlobalComponents();
setupGlobalIcons();

const app = {
  render: (h) => h(App),
  router,
  i18n,
};

new Vue(app).$mount('#app');
