import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import i18n, { getLocale, setLocale } from '@/i18n';
import { SUPPORTED_LOCALES } from '@/constants';

NProgress.configure({
  showSpinner: false,
});

Vue.use(VueRouter);

const routes = [
  {
    path: '/:locale',
    component: {
      name: 'RouterRoot',
      template: '<router-view/>',
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(
          /* webpackChunkName: "home" */
          '../views/home/Home.vue'
        ),
      },
    ],
  },
  {
    path: '*',
    redirect: i18n.locale,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.params.skipScroll) {
      return null;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

let timer = null;

router.beforeEach((to, from, next) => {
  if (!SUPPORTED_LOCALES.includes(to.params.locale)) {
    next({ path: `${getLocale()}${to.fullPath}` });
    return;
  }
  if (to.name !== from.name && !timer) {
    timer = setTimeout(() => {
      NProgress.start();
    }, 250);
  }
  if (to.params.locale !== from.params.locale) {
    setLocale(to.params.locale);
  }
  next();
});

router.afterEach((to, from) => {
  if (to.name !== from.name && timer) {
    timer = clearTimeout(timer);
    NProgress.done();
  }
});

router.onError(() => {
  window.location.reload(true);
});

export default router;
