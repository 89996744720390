import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/constants';
import { LocaleTypes } from '@/constants/enums';
import ar_messages from './ar/messages.json';
import en_messages from './en/messages.json';

Vue.use(VueI18n);

const messages = {
  ar: {
    ...ar_messages,
  },
  en: {
    ...en_messages,
  },
};

export function getLocale() {
  return localStorage.getItem('nawa-locale') || DEFAULT_LOCALE;
}

const i18n = new VueI18n({
  messages,
  locale: getLocale(),
  fallbackLocale: DEFAULT_LOCALE,
  silentTranslationWarn: process.env.NODE_ENV !== 'production',
});

export function setLocale(lang) {
  if (!SUPPORTED_LOCALES.includes(lang)) return;
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
  window.document.title = i18n.t('general.nawa');
  document.dir = lang === LocaleTypes.Ar ? 'rtl' : 'ltr';
  document.documentElement.lang = lang;
  localStorage.setItem('nawa-locale', lang);
}

export default i18n;
